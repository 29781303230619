import React from "react"
import Layout from "../components/Layout"
import PageHeader from '../components/PageHeader'
import ContainerWrapper from '../components/Container'
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from "react-helmet"

const ContactUs = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact us - IMSSDARM Asian Division</title>
        <link rel="canonical" href="https://imssdarm.asia/contact-us" />
        <meta name="description" content="Contact us to get more details about our church activities"></meta>
      </Helmet>
      <div className="churches">
        <PageHeader>
          <h1>Contact us</h1>
        </PageHeader>

        <ContainerWrapper>
          <div className="container">
            <div className="content row">
              <div className="col-md-6">
                <h3>Where to find us:</h3>

                <h4 className="mt-5">Philippines </h4>
                <p>941 San Isidro Talisay City Cebu 6045</p>
                <p>5-26 Old Pampang Resettlement, Pampang, Angeles City, Philippines 2009</p>

                <h4>Korea</h4>
                <p>31, Jeonwon 1-gil, Umsa-myeon, Gyeryong-si, Chungcheongnam-do, Republic of Korea</p>
              </div>
              <div className="col-md-6">
                <h3>Send us email:</h3>
                <form name="IMSSDARM ASIA - New contact" netlify-honeypot="bot-field" data-netlify="true">
                  <FormGroup className="mt-5">
                    <Label for="firstName">First name</Label>
                    <Input type="text" name="firstname" id="firstName" placeholder="Enter your first name" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="lastName">Last name</Label>
                    <Input type="text" name="lastname" id="lastName" placeholder="Enter your last name" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="Email">Email</Label>
                    <Input type="email" name="lastname" id="Email" placeholder="Enter your Email" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="Message">Message</Label>
                    <Input type="textarea" name="text" id="Message" />
                    <Input type="hidden" name="bot-field" />
                    <Input type="hidden" name="form-name" value="contact" />
                  </FormGroup>
                  <Button className="btn btn-primary">Submit</Button>
                </form>
              </div>
            </div>
          </div>
        </ContainerWrapper>

      </div>
    </Layout>
  )
}

export default ContactUs;
